import React from 'react';
import { Grid, TextField, Box, Typography, Divider } from '@mui/material';

const AddressForm = ({ formik }) => {
    return (
        <Box sx={{ maxWidth: 800, margin: '0 auto', padding: 2 }}>
            <form onSubmit={formik.handleSubmit}>
                <Divider>
                    <Typography variant="h5" align="center" gutterBottom>
                        🏠 Datos de Trabajo
                    </Typography>
                </Divider>
                <Typography
                    variant="body1"
                    align="center"
                    gutterBottom
                    sx={{ mb: 2, color: 'gray' }}
                >
                    Esta sección de carácter opcional, tiene la intención de
                    conocer la ubicación de su lugar de trabajo con el objetivo
                    de que ésta, pueda ser considerada al momento de la
                    aceptación del alumno a preinscribir. 📍
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Calle"
                            name="work_street"
                            value={formik.values.work_street}
                            onChange={(e) => {
                                const value = e.target.value.slice(0, 50);
                                formik.setFieldValue('work_street', value);
                            }}
                            inputProps={{
                                maxLength: 50,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.work_street &&
                                Boolean(formik.errors.work_street)
                            }
                            helperText={
                                formik.touched.work_street &&
                                formik.errors.work_street
                                    ? formik.errors.work_street
                                    : `Caracteres disponibles: ${
                                          formik.values.work_street?.length || 0
                                      }/50`
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Número Exterior"
                            name="work_external_number"
                            value={formik.values.work_external_number}
                            onChange={(e) => {
                                const value = e.target.value.slice(0, 20);
                                formik.setFieldValue(
                                    'work_external_number',
                                    value
                                );
                            }}
                            inputProps={{
                                maxLength: 20,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.work_external_number &&
                                Boolean(formik.errors.work_external_number)
                            }
                            helperText={
                                formik.touched.work_external_number &&
                                formik.errors.work_external_number
                                    ? formik.errors.work_external_number
                                    : `Caracteres disponibles: ${
                                          formik.values.work_external_number
                                              ?.length || 0
                                      }/20`
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Número Interior"
                            name="work_internal_number"
                            value={formik.values.work_internal_number}
                            onChange={(e) => {
                                const value = e.target.value.slice(0, 20);
                                formik.setFieldValue(
                                    'work_internal_number',
                                    value
                                );
                            }}
                            inputProps={{
                                maxLength: 20,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={
                                formik.touched.work_internal_number &&
                                Boolean(formik.errors.work_internal_number)
                            }
                            helperText={
                                formik.touched.work_internal_number &&
                                formik.errors.work_internal_number
                                    ? formik.errors.work_internal_number
                                    : `Caracteres disponibles: ${
                                          formik.values.work_internal_number
                                              ?.length || 0
                                      }/20`
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Colonia"
                            name="work_neighborhood"
                            value={formik.values.work_neighborhood}
                            onChange={(e) => {
                                const value = e.target.value.slice(0, 50);
                                formik.setFieldValue(
                                    'work_neighborhood',
                                    value
                                );
                            }}
                            inputProps={{
                                maxLength: 50,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.work_neighborhood &&
                                Boolean(formik.errors.work_neighborhood)
                            }
                            helperText={
                                formik.touched.work_neighborhood &&
                                formik.errors.work_neighborhood
                                    ? formik.errors.work_neighborhood
                                    : `Caracteres disponibles: ${
                                          formik.values.work_neighborhood
                                              ?.length || 0
                                      }/50`
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Código Postal"
                            name="work_zipcode"
                            value={formik.values.work_zipcode}
                            onChange={(e) => {
                                const value = e.target.value
                                    .replace(/\D/g, '')
                                    .slice(0, 5);
                                formik.setFieldValue('work_zipcode', value);
                            }}
                            inputProps={{
                                maxLength: 5,
                                pattern: '[0-9]*',
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onBlur={formik.handleBlur}
                            error={
                                formik.touched.work_zipcode &&
                                Boolean(formik.errors.work_zipcode)
                            }
                            helperText={
                                formik.touched.work_zipcode &&
                                formik.errors.work_zipcode
                                    ? formik.errors.work_zipcode
                                    : `Caracteres disponibles: ${
                                          formik.values.work_zipcode?.length ||
                                          0
                                      }/5`
                            }
                        />
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default AddressForm;
