import {
    Box,
    Typography,
    TextField,
    Button,
    Tooltip,
    IconButton,
    Chip,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import React, { useState } from 'react';
import Connection from '../../../../../service/Connection';
import { LoadingButton } from '@mui/lab';
import { UploadFileTwoTone } from '@mui/icons-material';
import { Avatar, Card, Divider, Progress, Skeleton, Space } from 'antd';
import Meta from 'antd/lib/card/Meta';
import SchoolIcon from '../../../../assets/iconos/school-svg-com.svg';
import childrenIcon from '../../../../assets/iconos/children.svg';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Map, Marker } from 'pigeon-maps';
import { useFeedback } from '../../../../../hooks';
import { useShowContainer } from '../../../../services/Container';
import { getLevelName, getTurnLevel } from '../../../../../libs/utils'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const uuidRegex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
const phoneRegex = /^[0-9]{10}$/;

const validationSchema = Yup.object({
    identifier: Yup.string()
        .required('Campo requerido')
        .test(
            'is_valid',
            'Debe ser un Folio válido o número de teléfono válido',
            (value) => {
                return uuidRegex.test(value) || phoneRegex.test(value);
            }
        ),
});

export const Following = () => {
    /////////// SHARED STATE ///////////
    const feedbackApp = useFeedback();
    const { showContainer, setShowContainer, uuid } = useShowContainer();

    ///////////STATES////////////

    const [loading, setLoading] = useState(false);
    const [Prescriptions, setPrescriptions] = useState([]);

    ///////////FUNCTION////////////
    const handleExportPDF = (postulationId) => {
        Connection.getPostulationsExportable('assignment-voucher', 'pdf', {
            id_postulacion: postulationId,
        })
            .then((response) => {
                const url = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `ficha_preinscripcion.pdf`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                feedbackApp.showFeedback({
                    title: '¡Ficha Descargada con Éxito! 🎉  Recuerda llevarla contigo a las instalaciones de la institución.',
                    severity: 'success',
                });
            })
            .catch((err) => {
                feedbackApp.showFeedback({
                    title: '¡Ups! Algo salió mal',
                    severity: 'error',
                });
            });
    };

    const handleSubmit = (values, { setSubmitting }) => {
        setLoading(true);
        setSubmitting(true);
        setPrescriptions([]);
        let finalValues = {};
        if (uuidRegex.test(values.identifier)) {
            finalValues = `id_preinscripcion=${values.identifier}`;
        } else if (phoneRegex.test(values.identifier)) {
            finalValues = `cellphone=${values.identifier}`;
        }

        Connection.getPendingPrescriptionsById(finalValues)
            .then((pre) => {
                let prescriptions = pre.data.data;

                const postulationsPromises = prescriptions.map((prescription) =>
                    Connection.getPendingPostulationsById(
                        prescription.preinscription_id,
                        finalValues
                    ).then((postulationResponse) => ({
                        prescription,
                        postulations: postulationResponse.data.data,
                    }))
                );
                return Promise.all(postulationsPromises);
            })
            .then((prescriptionsWithPostulations) => {
                const schoolPromises = prescriptionsWithPostulations.map(
                    (item) =>
                        Promise.all(
                            item.postulations.map((postulation) =>
                                Connection.getSchoolsByPostulations(
                                    postulation.postulation_id,
                                    finalValues
                                ).then((schoolResponse) => ({
                                    ...postulation,
                                    schools: schoolResponse.data.data,
                                }))
                            )
                        ).then((postulationsWithSchools) => ({
                            ...item.prescription,
                            postulations: postulationsWithSchools,
                        }))
                );
                return Promise.all(schoolPromises);
            })
            .then((prescriptionsWithPostulationsAndSchools) => {
                setPrescriptions(prescriptionsWithPostulationsAndSchools);
                feedbackApp.showFeedback({
                    title: '¡Postulaciones Encontradas! 🎉  Es muy importante que mantengas los datos de tu preinscripción seguros.',
                    severity: 'success',
                });
                setLoading(false);
                setSubmitting(false);
            })
            .catch((error) => {
                setLoading(false);
                setSubmitting(false);
                feedbackApp.showFeedback({
                    title: 'No se encontraron preinscripciones con la información ingresada.',
                    severity: 'error',
                });
            });
    };

    const getStatusPercentage = (status) => {
        switch (status) {
            case 1:
                return 25;
            case 2:
                return 100;
            default:
                return 0;
        }
    };

    const getStatusText = (status) => {
        switch (status) {
            case 1:
                return 'Por determinar';
            case 2:
                return 'Aceptada';
            default:
                return 'Desconocido';
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case 1:
                return '#FFBF43';
            case 2:
                return '#00C483';
            default:
                return 'gray';
        }
    };
    const getChipText = (priority) => {
        switch (priority) {
            case 1:
                return 'Primera Opción 🥇 ';
            case 2:
                return 'Segunda Opción 🥈';
            case 3:
                return 'Tercera Opción 🥉';
            default:
                return '';
        }
    };

    return (
        <Box sx={{ maxWidth: 800, margin: '0 auto', padding: 2 }}>
            <Typography
                variant="h5"
                align="center"
                gutterBottom
                style={{ color: '#919399' }}>
                ¡Bienvenido/a al seguimiento de tu preinscripción! 🎉
            </Typography>
            <Divider />
            <Typography
                variant="subtitle1"
                gutterBottom
                className="py-2"
                style={{ color: '#757575', fontSize: '1.1rem' }}>
                - Debes de contar con tu{' '}
                <span style={{ color: '#000', fontWeight: 'bold', }}>
                    Folio de preinscripción{' '}
                </span>
                el cual obtuviste al momento de terminar el proceso de preinscripción
                <br />- Esta seccion es para consultar el estado de tu preinscripción.
            </Typography>

            <Divider />
            <Box className="mt-4">
                <Formik
                    initialValues={{ identifier: uuid || '' }}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}>
                    {({ errors, touched, dirty, isValid, isSubmitting }) => (
                        <Form>
                            <Box display="flex" alignItems="center" gap={2}>
                                <Field
                                    name="identifier"
                                    as={TextField}
                                    variant="outlined"
                                    color="primary"
                                    label="Ingresa tu folio de seguimiento o número de teléfono"
                                    error={Boolean(
                                        errors.identifier && touched.identifier
                                    )}
                                    helperText={
                                        errors.identifier && touched.identifier
                                            ? errors.identifier
                                            : ''
                                    }
                                    fullWidth
                                    margin="none"
                                    sx={{ height: '3.5rem', }}
                                    disabled={isSubmitting}
                                />
                                <LoadingButton
                                    loadingPosition="start"
                                    variant="outlined"
                                    color="primary"
                                    type="submit"
                                    loading={isSubmitting}
                                    disabled={isSubmitting || !isValid}
                                    startIcon={<UploadFileTwoTone />}
                                    sx={{
                                        height: '3.5rem',
                                        minWidth: '9.375rem',
                                    }}
                                    className="">
                                    {isSubmitting
                                        ? 'Procesando...'
                                        : 'Consultar'}
                                </LoadingButton>
                            </Box>

                            <Box className="mt-5">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    fullWidth
                                    margin="normal"
                                    onClick={() => setShowContainer('inicio')}>
                                    Regresar
                                </Button>
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
            <Divider />

            <Skeleton loading={loading} avatar active />

            <Space
                direction="vertical"
                size="middle"
                loading={loading}
                style={{ display: 'flex' }}></Space>
            <Space className="w-full" direction="vertical">

                {Prescriptions.map((prescription, index) => (
                    <div key={index}>
                        <Box display="flex" alignItems="stretch" gap={4} justifyContent="center">

                            <Skeleton loading={loading} avatar active>
                                <Box flex={1} p={2} className="rounded-xl shadow-lg" minWidth={500} maxWidth={500}>
                                    <Box display="flex" justifyContent="center" mb={1}  >
                                        <Typography variant="h6" className="font-semibold" style={{ color: "#8c8c8c" }} m={1}>
                                            Información del Alumno/a
                                        </Typography>
                                    </Box>
                                    <Meta
                                        avatar={
                                            <Avatar src={childrenIcon} className="ml-3 mt-2 !w-14 !h-14" sx={{ width: 65, height: 65 }} />
                                        }
                                        title={

                                            <Typography variant="h6" className="font-semibold" style={{ wordWrap: "break-word", overflowWrap: "break-word" }} >
                                                {prescription.name} {prescription.last_name} {prescription.second_last_name}{" "}
                                            </Typography>

                                        }
                                        description={

                                            <Box>
                                                <Box display="flex" className="font-bold mb-1">
                                                    <Typography variant="body2" className="font-bold mr-2">
                                                        Grado:
                                                    </Typography>
                                                    <Typography variant="body2"> {prescription.grade ? prescription.grade : "N/A"}</Typography>
                                                </Box>
                                                <Box display="flex" className="font-bold mb-1">
                                                    <Typography variant="body2" className="font-bold mr-2">
                                                        CURP:
                                                    </Typography>
                                                    <Typography variant="body2"> {prescription.curp ? prescription.curp : "N/A"}</Typography>
                                                </Box>
                                                <Box display="flex" className="font-bold mb-1">
                                                    <Typography variant="body2" className="font-bold mr-2">
                                                        Dirección:
                                                    </Typography>
                                                    <Typography variant="body2" style={{ wordWrap: "break-word", overflowWrap: "break-word" }}>
                                                        {prescription.home_street}, #{prescription.home_external_number},{" "}
                                                        {prescription.home_zipcode}, {prescription.home_neighborhood}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        }

                                    />
                                </Box>
                            </Skeleton>


                            <Skeleton loading={loading} avatar active>
                                <Box flex={1} p={2} className="rounded-xl shadow-lg" minWidth={500} maxWidth={500}>
                                    <Box display="flex" justifyContent="center" mb={1}  >
                                        <Typography variant="h6" className="font-semibold" style={{ color: "#8c8c8c" }} m={1}>
                                            Información de Contacto
                                        </Typography>
                                    </Box>
                                    <Meta
                                        avatar={

                                            <AccountCircleIcon className="text-gray-200 h-20 w-20" />

                                        }

                                        title={
                                            <Typography variant="h6" className="font-semibold" style={{ wordWrap: "break-word", overflowWrap: "break-word" }} >
                                                {prescription.parent_name} {prescription.parent_last_name}{" "}
                                                {prescription.parent_second_last_name}
                                            </Typography>
                                        }
                                        description={
                                            <Box>
                                                <Box display="flex" className="font-bold mb-1">
                                                    <Typography variant="body2" className="font-bold mr-2">
                                                        Teléfono:
                                                    </Typography>
                                                    <Typography variant="body2">{prescription.parent_phone}</Typography>
                                                </Box>
                                                <Box display="flex" className="font-bold mb-1">
                                                    <Typography variant="body2" className="font-bold mr-2">
                                                        Email:
                                                    </Typography>
                                                    <Typography variant="body2" style={{ wordWrap: "break-word", overflowWrap: "break-word" }}>
                                                        {prescription.parent_email}
                                                    </Typography>
                                                </Box>
                                                <Box display="flex" className="font-bold mb-1">
                                                    <Typography variant="body2" className="font-bold mr-2">
                                                        Dirección:
                                                    </Typography>
                                                    <Typography variant="body2" style={{ wordWrap: "break-word", overflowWrap: "break-word" }}>
                                                        {prescription.parent_home_street}, #{prescription.parent_home_external_number},{" "}
                                                        {prescription.parent_home_neighborhood}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        }
                                    />
                                </Box>
                            </Skeleton>
                        </Box>

                        <Divider />
                        <Space
                            direction="horizontal"
                            size="middle"
                            style={{ display: "flex", justifyContent: "center", width: "100%", alignItems: "stretch" }}>
                            {prescription.postulations.map((postulation, postIndex) => (
                                <Card
                                    key={postIndex}
                                    hoverable
                                    style={{
                                        marginBottom: 16,
                                        minWidth: 600,
                                        maxWidth: 600,
                                        width: "100%",

                                    }}
                                    loading={loading}
                                    className="rounded-xl overflow-hidden shadow-lg w-full "
                                    cover={
                                        loading ? (
                                            <Skeleton.Image
                                                className="w-full"
                                                style={{ height: 300 }}
                                            />
                                        ) : (
                                            <>
                                                {postulation.schools.latitude && postulation.schools.longitude && (
                                                    <Box className="absolute top-2 bg-white m-1 rounded-xl shadow-lg z-10 left-1/2 transform -translate-x-1/2 w-10/12 sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-5/12">
                                                        <Typography
                                                            variant="body2"
                                                            className="font-bold"
                                                            align="center"
                                                            mt={0.5}>
                                                            Dirección de la escuela
                                                        </Typography>
                                                        <Typography variant="body2" align="center">
                                                            {postulation.schools.street}, #{postulation.schools.inside_number},{' '}
                                                            {postulation.schools.zipcode},{' '}{postulation.schools.location}
                                                        </Typography>
                                                        <Box display="flex" justifyContent="center" mt={0.1}>
                                                            <Button
                                                                variant="text"
                                                                color="primary"
                                                                onClick={() => {
                                                                    const url = `https://www.google.com/maps/dir/?api=1&destination=${postulation.schools.latitude},${postulation.schools.longitude}`;
                                                                    window.open(url, '_blank');
                                                                }}>
                                                                Ver indicaciones de llegada
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                )}
                                                {postulation.schools.latitude && postulation.schools.longitude && (
                                                    <Map
                                                        height={250}
                                                        style={{ paddingTop: "50px" }}
                                                        defaultCenter={[
                                                            (postulation.schools.latitude || 24.0226) + .0025,
                                                            (postulation.schools.longitude || -104.6532) - .0005,
                                                        ]}
                                                        mouseEvents={true}
                                                        touchEvents={true}
                                                        zoomSnap={true}
                                                        zoomDelta={0}
                                                        defaultZoom={15}>
                                                        {postulation.schools.longitude && postulation.schools.latitude && (
                                                            <Marker
                                                                width={50}
                                                                anchor={[
                                                                    postulation.schools.latitude,
                                                                    postulation.schools.longitude,
                                                                ]}>
                                                                <img src={SchoolIcon} width={50} height={50} alt="marker" />
                                                            </Marker>
                                                        )}
                                                    </Map>
                                                )}
                                            </>
                                        )
                                    }
                                    actions={[
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                            width="100%"
                                            key="actions">

                                            {postulation.status === 2 && (
                                                <Tooltip

                                                    title="Descargar Ficha de Preinscripción en PDF"
                                                    arrow>
                                                    <IconButton
                                                        onClick={() => handleExportPDF(postulation.postulation_id)}
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            margin: -1,
                                                            width: 'auto',
                                                            height: 'auto',
                                                            padding: 0,
                                                            borderRadius: 2,
                                                            transition:
                                                                'all 0.3s ease',
                                                            '&:hover': {
                                                                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                                                                borderRadius: 0,
                                                                width: 'auto',
                                                                height: 'auto',
                                                            },
                                                        }}>
                                                        <FileDownloadOutlinedIcon
                                                            style={{ width: 40, height: 40, }}
                                                        />
                                                        <Typography variant="body2" className="font-bold" sx={{ marginTop: 1, }}>
                                                            Descarga tu Ficha de Preinscripción
                                                        </Typography>
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </Box>,
                                    ]}>
                                    <Box display="flex" alignItems="center" gap={3} sx={{ margin: -2, marginLeft: 1, }}>
                                        <Meta
                                            avatar={
                                                <Avatar src={SchoolIcon} sx={{ marginBottom: 0 }} />
                                            }

                                            title={
                                                <Box display="flex">

                                                    <Box sx={{ maxWidth: "70%", whiteSpace: "normal", wordBreak: "break-word", }}>
                                                        <Typography variant="h6" className="font-bold mr-5">
                                                            {postulation.schools.name}
                                                        </Typography>
                                                    </Box>

                                                    <Box display="flex">
                                                        {prescription.postulations
                                                            .filter(currentPostulation => currentPostulation.school_id === postulation.school_id)
                                                            .map(filteredPostulation => (
                                                                <Chip
                                                                    key={filteredPostulation.postulation_id}
                                                                    label={getChipText(filteredPostulation.priority)}
                                                                    sx={{
                                                                        backgroundColor: "white",
                                                                        borderColor: "#005A99",
                                                                        color: "#003F66",
                                                                        borderWidth: "1px",
                                                                        borderStyle: "solid",
                                                                    }}
                                                                />
                                                            ))}
                                                    </Box>
                                                </Box>
                                            }


                                            description={

                                                <Box marginTop={1.5} marginBottom={1} width="100%" >

                                                    <Box display={"flex"} gap={1} marginBottom={1} >
                                                        <Typography variant="body2" className="font-bold"> CCT:</Typography>
                                                        <Typography variant="body2" className="font-normal mr-3 ">{postulation.schools.clave}</Typography>
                                                        <Typography variant="body2" className="font-bold"> Teléfono:</Typography>
                                                        <Typography variant="body2" className="font-normal mr-3 ">{postulation.schools.cellphone}</Typography>
                                                    </Box>
                                                    <Box display={"flex"} gap={1} marginBottom={1}  >
                                                        <Typography variant="body2" className="font-bold"> Nivel:</Typography>
                                                        <Typography variant="body2" className="font-normal mr-3 ">{getLevelName(postulation.schools.level)}</Typography>
                                                        <Typography variant="body2" className="font-bold"> Turno:</Typography>
                                                        <Typography variant="body2" className="font-normal mr-3 ">{getTurnLevel(postulation.schools.turn)}</Typography>

                                                    </Box>
                                                </Box>


                                            }
                                        />

                                    </Box>
                                    <Divider />
                                    <Box style={{ marginTop: -20, marginLeft: 1, }}>
                                        <Typography variant="h7" className="font-medium "> Proceso de aceptación </Typography>
                                        <Box
                                            key={postulation.postulation_id}
                                            display="flex"
                                            flexDirection="column"
                                            alignItems="flex-start"
                                            style={{ padding: 0, margin: 0, }}>
                                            <Typography
                                                variant="body2"
                                                style={{ color: getStatusColor(postulation.status), }}>
                                                {getStatusText(postulation.status)}
                                            </Typography>
                                            <Progress
                                                percent={getStatusPercentage(postulation.status)}
                                                showInfo={false}
                                                strokeColor={getStatusColor(postulation.status)}
                                                style={{ width: '100%' }}
                                            />
                                        </Box>
                                    </Box>
                                </Card>
                            )
                            )}
                        </Space>
                    </div>
                ))}
            </Space>
        </Box>
    );
};
