import React, { createContext, useState, useContext } from 'react';

const ShowContainerContext = createContext();

export const ShowContainerProvider = ({ children }) => {
    const [showContainer, setShowContainer] = useState('inicio');

    const [uuid, setuuid] = useState('');
    
    return (
        <ShowContainerContext.Provider
            value={{ showContainer, setShowContainer, uuid, setuuid }}>
            {children}
        </ShowContainerContext.Provider>
    );
};

export const useShowContainer = () => useContext(ShowContainerContext);
