import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import logoHeader from '../assets/logo_header.svg';
import { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import BuildIcon from '@mui/icons-material/Build';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import LoginIcon from '@mui/icons-material/Login';
import Divider from '@mui/material/Divider';
import palomita from '../assets/palomita.svg';
import Drawer from '@mui/material/Drawer';
import { VerifiedUser } from '@mui/icons-material';
import FeatureFlags from '../../service/FeatureFlags';
import { useShowContainer } from '../services/Container';

function NavBar() {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const { setShowContainer } = useShowContainer();
    const history = useHistory();

    const featureFlagPrescriptions = FeatureFlags.isFeatureFlagActive(
        'NEW_PRE_REGISTRATIONS'
    );

    const openPreinscriptions = JSON.parse(localStorage.getItem('activeLevel'));

    const pages = [
        { title: 'Inicio', path: '/', icon: <HomeIcon /> },
        {
            title: 'Beneficios',
            path: '/beneficios',
            icon: <CardGiftcardIcon />,
        },
        {
            title: 'Funcionalidades',
            path: '/funcionalidades',
            icon: <BuildIcon />,
        },
        { title: 'Contacto', path: '/contacto', icon: <ContactMailIcon /> },
        featureFlagPrescriptions &&
            openPreinscriptions && {
                title: (
                    <div
                        style={{
                            position: 'relative',
                            display: 'inline-block',
                            textAlign: 'center',
                        }}>
                        <span
                            className="bg-amber-500"
                            style={{
                                position: 'absolute',
                                top: '-35px',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                backgroundColor: '#d32f2f',
                                color: '#ffffff',
                                borderRadius: '8px',
                                padding: '6px 12px',
                                fontSize: '0.8rem',
                                fontWeight: 'bold',
                                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
                                whiteSpace: 'nowrap',
                            }}>
                            🚀 Versión en prueba
                            <span
                                style={{
                                    content: "''",
                                    position: 'absolute',
                                    bottom: '-6px',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    width: '0',
                                    height: '0',
                                    borderLeft: '6px solid transparent',
                                    borderRight: '6px solid transparent',
                                    borderTop: '6px solid #facc15',
                                }}
                            />
                        </span>
                        Preinscripciones
                    </div>
                ),
                path: '/preinscripciones',
                icon: <VerifiedUser />,
            },
        { title: 'Ingresar', path: '/Login', icon: <LoginIcon /> },
    ].filter(Boolean);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleNavLinkClick = (path) => {
        if (path === '/preinscripciones') {
            setShowContainer('inicio');
        }
        handleCloseNavMenu();
        history.push(path);
    };

    return (
        <AppBar className="head" position="sticky">
            <Container
                sx={{ backgroundColor: '#2e333e' }}
                maxWidth="x1"
                className="navbar">
                <Toolbar className="container" disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            color: 'inherit',
                            textDecoration: 'none',
                        }}>
                        <NavLink to="/">
                            <img className="logo" src={logoHeader} alt="Logo" />
                        </NavLink>
                    </Typography>

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'flex', md: 'none' },
                            alignItems: 'right',
                        }}>
                        <IconButton
                            size="large"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            sx={{ color: '#ffffff' }}>
                            <MenuIcon />
                        </IconButton>
                        <Drawer
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                                position: 'relative',
                                width: '100%',
                            }}>
                            <Box
                                sx={{
                                    height: '100%',
                                    position: 'relative',
                                    backgroundColor: '#2e333e',
                                }}>
                                <Divider
                                    sx={{
                                        backgroundColor: '#2e333e',
                                        padding: 5,
                                    }}>
                                    <img className="palomita" src={palomita} />
                                </Divider>
                                {pages
                                    .filter((page) => page.title !== 'Ingresar')
                                    .map((page) => (
                                        <MenuItem
                                            key={page.title}
                                            onClick={() =>
                                                handleNavLinkClick(page.path)
                                            }
                                            sx={{
                                                backgroundColor: '#2e333e',
                                                padding: '20px',
                                            }}>
                                            <Button
                                                startIcon={page.icon}
                                                style={{
                                                    width: '100%',
                                                    textDecoration: 'none',
                                                    color: '#ffffff',
                                                }}>
                                                {page.title}
                                            </Button>
                                        </MenuItem>
                                    ))}
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        bottom: 0,
                                        backgroundColor: '#2e333e',
                                        width: '100%',
                                        alignContent: 'center',
                                        '@media (max-height: 500px)': {
                                            position: 'static',
                                        },
                                    }}>
                                    <MenuItem
                                        key="Ingresar"
                                        sx={{ padding: 0 }}
                                        onClick={handleCloseNavMenu}>
                                        <NavLink
                                            to="/NewLogin"
                                            style={{
                                                width: '100%',
                                                textDecoration: 'none',
                                            }}>
                                            <Button
                                                fullWidth
                                                sx={{
                                                    backgroundColor: '#00C483 ',
                                                    '&:hover': {
                                                        backgroundColor:
                                                            '#029b68 ',
                                                    },
                                                    color: '#ffffff',
                                                }}
                                                startIcon={<LoginIcon />}>
                                                Ingresar
                                            </Button>
                                        </NavLink>
                                    </MenuItem>
                                </Box>
                            </Box>
                        </Drawer>
                    </Box>

                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            color: 'inherit',
                            textDecoration: 'none',
                        }}>
                        <NavLink to="/">
                            <img
                                sx={{
                                    display: { xs: 'flex', md: 'none' },
                                    mr: 1,
                                }}
                                src={logoHeader}
                                alt="Logo"
                            />
                        </NavLink>
                    </Typography>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'none', md: 'flex' },
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                        {pages.slice(0, -1).map((page) => (
                            <Button
                                key={page.title}
                                onClick={() => handleNavLinkClick(page.path)}
                                sx={{
                                    my: 2,
                                    color: '#ffffff',
                                    display: 'block',
                                    textAlign: 'center',
                                }}>
                                {page.title}
                            </Button>
                        ))}
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <NavLink
                            to="/NewLogin"
                            style={{
                                textDecoration: 'none',
                                color: 'inherit',
                            }}>
                            <Button
                                startIcon={<LoginIcon />}
                                key="Ingresar"
                                onClick={handleCloseNavMenu}
                                sx={{
                                    width: '150px',
                                    height: '50px',
                                    my: 1,
                                    color: '#ffffff',
                                    display: 'flex',
                                    borderRadius: '30px',
                                    backgroundColor: '#00C483 ',
                                    '&:hover': {
                                        backgroundColor: '#029b68 ',
                                    },
                                }}>
                                Ingresar
                            </Button>
                        </NavLink>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default NavBar;
