import { Box, Button, Divider, IconButton } from '@mui/material';
import React, { useState } from 'react';

import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import SchoolIcon from '../../../../assets/iconos/school-svg-com.svg';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

import ListItemText from '@mui/material/ListItemText';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import CloseDialog from '../Summary/components/dialog'

import Connection from '../../../../../service/Connection'
import { useFeedback } from '../../../../../hooks';

import { Map, Marker } from "pigeon-maps"
import Feedback from '../../../../../service/Feedback';

import LoadingButton from '@mui/lab/LoadingButton';

import Modal from '@mui/material/Modal';

import VisibilityIcon from '@mui/icons-material/Visibility';

import CloseIcon from '@mui/icons-material/Close';

import Tooltip from '@mui/material/Tooltip';
import { parentescoCheck, nacionalityCheck, genderCheck, priorityCheck } from '../../../../../libs/utils';


const StyledPaper = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    maxWidth: 800,
    color: theme.palette.text.primary,
}));

export const Summary = ({ data, handleBack, uuid }) => {

    const feedbackApp = useFeedback();
    let FeedbackService = new Feedback();

    const [checked, setChecked] = useState(true);

    const [open, setOpen] = useState(false);
    const handleOpen = () => { setOpen(true) };
    const handleClose = () => { setOpen(false) };

    const [selectFileUrl, setSelectedFileUrl] = useState();

    const [openDoc, setOpenDoc] = useState(false);
    const handleOpenDoc = () => setOpenDoc(true);
    const handleCloseDoc = () => setOpenDoc(false);

    const [loading, setLoading] = useState(false);

    const [isDisabled, setIsDisabled] = useState(true);

    const transformInitialLetter = (data) => { return data.replace(/\b\w/g, char => char.toUpperCase()); };

    const latitude = parseFloat(data.latitude);
    const longitude = parseFloat(data.longitude);

    const isForeign = nacionalityCheck(data.student.is_foreign);
    const isInmigrant = nacionalityCheck(data.student.is_immigrant);
    const isIndigenous = nacionalityCheck(data.student.is_indigenous);
    const parentGender = genderCheck(data.parent.parent_gender);
    const studentGender = genderCheck(data.student.gender);
     
    const confirmPreInscription = async () => {

        setLoading(true);

        let postulationsP = data.postulations.map(postulation => (
            {
                priority: postulation.priority,
                school_id: postulation.school.school_id,
                siblings_number: postulation.siblings_number
            }
        ));

        const preinscripcionData = { ...data, postulations: postulationsP, uuid: uuid };

        let report = "preinscription-voucher"
        let format = "pdf"

        try {
            await Connection.setPreRegistration(
                preinscripcionData
            ).then((res) => {

                const uuidresp = {
                    uuid: res.data.data.preinscription_id
                }

                Connection.getExportablePreinscription(
                    report,
                    format,
                    uuidresp
                ).then((resp) => {

                    const url = window.URL.createObjectURL(new Blob([resp.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `solicitud_de_preincripcion.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    handleOpen();
                    setLoading(false);

                }).catch((error) => {
                    let feedbackError = FeedbackService.getMessage(error);

                    feedbackApp.showFeedback({
                        title: feedbackError.title,
                    });
                });


                feedbackApp.showFeedback({
                    title: 'preinscripcion hecha',
                });
            })
        } catch (error) {

            let feedbackError = FeedbackService.getMessage(error);

            feedbackApp.showFeedback({
                title: feedbackError.title,
            });

            setLoading(false);
        }
    };

    const handleChange = (event) => {
        setChecked(event.target.checked);
      
        setIsDisabled(!isDisabled);
    };

    const handleViewFile = (fileObject) => {

        if (fileObject && fileObject.file instanceof File) {
            const fileUrl = URL.createObjectURL(fileObject.file);
            
            setSelectedFileUrl(fileUrl);
            handleOpenDoc(true);
        } else {
            feedbackApp.showFeedback({
                title: 'No se pudo visualizar el archivo',
                severity: 'error',
            });
        }
    };

    return (
        <Box sx={{ maxWidth: 800, margin: '0 auto', padding: 2 }}>

            <Divider>
                <Typography variant="h5" align="center" gutterBottom>
                    Resumen
                </Typography>
            </Divider>
            <Typography
                variant="body1"
                align="center"
                gutterBottom
                sx={{ mb: 2, color: 'gray' }}
            >
                En este apartado, proporciona la información personal
                básica del tutor, como información del estudiante, documentos cargados y postulaciones.
            </Typography>

            <StyledPaper
                sx={{
                    my: 1,
                    mx: 'auto',
                    p: 2,
                }}
            >
                <Grid container wrap="nowrap" spacing={2}>

                    <Grid item xs zeroMinWidth>

                        <Box>
                            <Typography variant="h5" p={3}>
                                Datos del Alumno
                            </Typography>
                        </Box>

                        <Grid container spacing={2} align="center">
                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Nombre" secondary={transformInitialLetter(data.student?.name)} />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Apellido paterno" secondary={data.student.last_name} />
                            </Grid>
                        </Grid>

                        <Box mb={2} />

                        <Grid container spacing={2} align="center">
                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Apellido materno" secondary={data.student.second_last_name} />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Estado de nacimiento" secondary={data.student.born_state_name} />
                            </Grid>
                        </Grid>

                        <Box mb={2} />

                        <Grid container spacing={2} align="center">
                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Municipio de nacimiento" secondary={data.student.born_municipality_name} />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Ciudad de nacimiento" secondary={data.student.born_city_name} />
                            </Grid>
                        </Grid>

                        <Box mb={2} />

                        <Grid container spacing={2} align="center">
                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Fecha de nacimiento" secondary={data.student.birth_date} />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Talla" secondary={data.student.size} />
                            </Grid>
                        </Grid>


                        <Box mb={2} />

                        <Grid container spacing={2} align="center">
                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Género" secondary={studentGender.label} />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }} >
                                <ListItemText primary="Grado" secondary={data.student.grade} />
                            </Grid>
                        </Grid>

                        <Box mb={2} />

                        <Grid container spacing={2} align="center">
                            <Grid item xs={12} sm={4} style={{ padding: 0 }}>
                                <ListItemText primary="Es indígena" secondary={isIndigenous.label} />
                            </Grid>

                            <Grid item xs={12} sm={4} style={{ padding: 0 }}>
                                <ListItemText primary="Es extranjero" secondary={isForeign.label} />
                            </Grid>

                            <Grid item xs={12} sm={4} style={{ padding: 0 }} >
                                <ListItemText primary="Es Inmigrante" secondary={isInmigrant.label} />
                            </Grid>
                        </Grid>

                        <Box mb={2} />

                        <Grid container spacing={2} align="center">
                            <Grid item xs={12} sm={12} style={{ padding: 0 }}>
                                <ListItemText primary="Curp" secondary={data.student.curp} />
                            </Grid>
                        </Grid>

                        <Box mb={2} />

                        <Box>
                            <Typography variant="h5" p={3}>
                                Datos del domicilio
                            </Typography>
                        </Box>

                        <Grid container spacing={2} align="center">

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Calle" secondary={data.student.home_street} />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Número exterior" secondary={data.student.home_external_number} />
                            </Grid>

                        </Grid>

                        <Box mb={1} />

                        <Grid container spacing={2} align="center">

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Número interior" secondary={data.student.home_internal_number} />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Colonia" secondary={data.student.home_neighborhood} />
                            </Grid>

                        </Grid>

                        <Box mb={1} />

                        <Grid container spacing={2} align="center">

                            <Grid item xs={12} sm={6} align="center" style={{ padding: 0 }}>
                                <ListItemText primary="Código postal" secondary={data.student.home_zipcode} />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Estado" secondary={data.student.address_state_name} />
                            </Grid>

                        </Grid>

                        <Box mb={1} />

                        <Grid container spacing={2} align="center">
                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Municipio" secondary={data.student.born_municipality_name} />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Ciudad" secondary={data.student.address_city_name} />
                            </Grid>
                        </Grid>

                    </Grid>
                </Grid>
            </StyledPaper>

            <StyledPaper
                sx={{
                    my: 1,
                    mx: 'auto',
                    p: 2,
                }}
            >
                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item xs>

                        <Box>
                            <Typography variant="h5" p={3}>
                                Datos del Contacto
                            </Typography>
                        </Box>

                        <Grid container spacing={2} align="center">
                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Nombre" secondary={transformInitialLetter(data.parent.parent_name)} />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Apellido Paterno" secondary={data.parent.parent_last_name} />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Apellido materno" secondary={data.parent.parent_second_last_name} />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Email" secondary={data.parent.parent_email} />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Celular" secondary={data.parent.parent_phone} />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Fecha de nacimiento" secondary={data.parent.parent_birth_date} />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Genero" secondary={parentGender.label} />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Relacion/Parentesco"  secondary={`${parentescoCheck(data.parent.relationship).label}`}/>
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Calle" secondary={data.parent.parent_home_street} />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Numero Exterior" secondary={data.parent.parent_home_external_number} />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Numero Interior" secondary={data.parent.parent_home_internal_number} />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Colonia" secondary={data.parent.parent_home_neighborhood} />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Codigo postal" secondary={data.parent.parent_home_external_number} />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Estado" secondary={data.parent.parent_birth_state_name} />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Municipio" secondary={data.parent.parent_birth_city_name} />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Ciudad" secondary={data.parent.parent_birth_state_name} />
                            </Grid>
                        </Grid>

                        <Box mb={2} />

                        <Box>
                            <Typography variant="h5" mb={2} p={3}>
                                Datos del trabajo
                            </Typography>
                        </Box>

                        <Grid container spacing={2} align="center">
                            <Grid item xs={12} style={{ padding: 0 }}>
                                <ListItemText primary="Calle" secondary={data.parent.work_street} />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Numero exterior" secondary={data.parent.work_internal_number} />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Numero interior" secondary={data.parent.work_external_number} />
                            </Grid>

                            <Grid item xs={12} style={{ padding: 0 }}>
                                <ListItemText primary="Colonia" secondary={data.parent.work_neighborhood} />
                            </Grid>

                            <Grid item xs={12} sm={6} style={{ padding: 0 }}>
                                <ListItemText primary="Codigo postal" secondary={data.parent.work_zipcode} />
                            </Grid>
                        </Grid>

                        {/* {data.parent.contact} */}
                    </Grid>
                </Grid>
            </StyledPaper>

            <StyledPaper
                sx={{
                    my: 1,
                    mx: 'auto',
                    p: 2,
                }}
            >

                <Grid container wrap="nowrap" spacing={2}>
                    <Grid item xs>
                        <Box>
                            <Typography variant="h5" mb={2} p={3}>
                                Documentos
                            </Typography>
                        </Box>

                        <Grid item xs={12} sm={12} >

                            <Stack direction="row" spacing={1} flexWrap="wrap">
                                {Object.keys(data.archives).length > 0 ? (
                                    Object.keys(data.archives).map((key) => (
                                        <div key={key}>
                                            <Chip label={`${key}`} />

                                            <Tooltip title="Visualizar documento">
                                                <IconButton>
                                                    <VisibilityIcon
                                                        className="cursor-pointer text-blue-500 hover:text-blue-700 transition duration-300 ease-in-out"
                                                        onClick={() => handleViewFile(data.archives[key])}
                                                    />
                                                </IconButton>
                                            </Tooltip>

                                        </div>
                                    ))
                                ) : (
                                    <Typography align="center">
                                        Documentos no cargados
                                    </Typography>
                                )}
                            </Stack>

                        </Grid>
                    </Grid>
                </Grid>
            </StyledPaper>

            <StyledPaper
                sx={{
                    my: 1,
                    mx: 'auto',
                    p: 2,
                }}
            >
                <Grid container wrap="nowrap" spacing={2}>

                    <Grid item xs>

                        <Box>
                            <Typography variant="h5" p={3}>
                                Postulaciones
                            </Typography>
                        </Box>

                        <Grid container spacing={2} >
                            {data.postulations.map((postulation, index) =>
                            (<Grid item xs={12} sm={6} md={4} key={index} >
                                <Card sx={{ width: '100%', height: 220, marginBottom: 5 }} >
                                    <CardActionArea>
                                        <center>
                                            <Avatar>
                                                <AccountBalanceIcon />
                                            </Avatar>
                                        </center>
                                        <CardContent style={{ padding: 0 }}>
                                            <center>
                                                <Typography component="div">
                                                    {postulation.school.name}
                                                </Typography>

                                                <Typography>
                                                    Dirección: {postulation.school.colony}
                                                </Typography>

                                                <Typography>
                                                    Codigo Postal: {postulation.school.zip_code}
                                                </Typography>

                                                <Typography>
                                                    Estado: {postulation.school.state}
                                                </Typography>

                                                <Typography>
                                                    Teléfono: {postulation.school.phone}
                                                </Typography>

                                                <Typography>
                                                    <Chip label={`${priorityCheck(postulation.priority).label}`} />
                                                </Typography>

                                            </center>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container wrap="nowrap">
                    <Map
                        height={400}
                        defaultCenter={[
                            latitude, longitude
                        ]}
                        defaultZoom={11}
                    >
                        {data.postulations.map((postulation, index) =>
                        (
                            <Marker
                                width={50}
                                anchor={[
                                    postulation.school.latitude, postulation.school.longitude
                                ]}
                            >
                                <Typography>{postulation.school.name}</Typography>

                                <img
                                    src={SchoolIcon}
                                    width={50}
                                    height={50}
                                    alt="marker"
                                />
                            </Marker>
                        ))}
                    </Map>
                </Grid>
            </StyledPaper>

            <center>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2, maxWidth: 800 }}>
                    <Button type="default" onClick={handleBack}>
                        Regresar
                    </Button>

                    <Button
                        size="small"
                        sx={{
                            color: 'black',
                            textDecoration: 'underline',
                            fontWeight: 'bold',
                            padding: '0',
                        }}
                        target="_blank"
                        href={"/TerminosCondiciones"}
                    >
                        Términos y condiciones
                    </Button>

                    <FormControlLabel required control={<Checkbox onChange={handleChange} />} label="Aceptar terminos y condiciones" />

                    <LoadingButton
                        loading={loading}
                        loadingIndicator="Cargando..."
                        sx={{ background: 'green' }}
                        variant="contained"
                        onClick={confirmPreInscription}
                        disabled={isDisabled}
                    >
                        <span>Finalizar</span>
                    </LoadingButton>
                </Box>
            </center>

            <CloseDialog
                open={open} handleClose={handleClose} uuid={uuid}
            ></CloseDialog>


            <Modal
                open={openDoc}
                onClose={handleCloseDoc}
                aria-labelledby="modal-title"
                aria-describedby="modal-description">
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                    }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mb: 2,
                        }}>
                        <Typography
                            id="modal-title"
                            variant="h6"
                            component="h2">
                            Visualizar Archivo
                        </Typography>
                        <IconButton onClick={handleCloseDoc}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <iframe
                        src={selectFileUrl}
                        width="100%"
                        height="600px"
                        title="PDF Viewer"
                    />
                </Box>
            </Modal>

        </Box>
    )
}